import React, { Component, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import makeCancellablePromise from 'make-cancellable-promise';
import { collection, getDocs, query, where, doc, onSnapshot, limit, updateDoc } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import { setDefaultOptions } from 'date-fns';
import { id } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import Loading from './../components/Loading';
import { db, auth } from './../helper/firebase';

const Login = React.lazy(() => import('./Login/Login')),
      // Register = React.lazy(() => import('./Register/Register')),
// const Login = React.lazy(() => {
//         return new Promise(resolve => {
//           setTimeout(() => resolve(import('./Login/Login')), 300000);
//         });
//       }),
      Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      dataSignIn: {
        store: '',
        phone: '',
        nameFirst: '',
        nameLast: ''
      },
      signIn: '',
      dataStore: '',
      // dataStore: {
      //   id: '',
      //   uid: '',
      //   phone: '',
      //   email: '',
      //   name: '',
      //   about: '',
      //   province: '',
      //   city: '',
      //   district: '',
      //   postal: '',
      //   address: '',
      //   addressFull: '',
      //   approve: ''
      // },
      logOutLoading: false,
      setting: '',
      settingLoad: true
    }

    this.outLink = [{
      name: 'FAQ',
      link: 'https://indomakita.com/faq'
    }, {
      name: 'Ketentuan Penggunaan',
      link: 'https://indomakita.com/ketentuan-penggunaan'
    }, {
      name: 'Kebijakan Privasi',
      link: 'https://indomakita.com/kebijakan-privasi'
    }];
    
    this.cancellableStore = '';
    this.unsubscribeStore = '';
    this.cancellableLogout = '';
    this.cancellableSetting = '';
    this.cancellableUpdate = '';

    this.updateSign = this.updateSign.bind(this);
    this.logOut = this.logOut.bind(this);
    this.loadSetting = this.loadSetting.bind(this);
  }

  componentDidMount(){
    setDefaultOptions({ locale: id });
    
    onAuthStateChanged(auth, (user) => {
      if(user){
        let thePhone = '';
        
        if(user.email){
          thePhone = '+62' + user.email.substring(1).replace('@indomakita.id', '');
        }else{
          thePhone = user.email;
        }

        this.cancellableStore = makeCancellablePromise(
          getDocs(
            query(
              // collection(db, 'store'), where('storeStatus', '==', true), where('storeActive', '==', true), where('storeUid', '==', user.uid), limit(1)
              collection(db, 'store'), where('storeStatus', '==', true), where('storeActive', '==', true), where('storePhone', '==', thePhone), limit(1)
            )
          )
        );

        this.cancellableStore.promise.then((storeSnapshot) => {
          if(storeSnapshot.size){
            const storeDataFirst = storeSnapshot.docs[0].data(),
                  storeId = storeSnapshot.docs[0].id;

            // console.log(user.uid, storeDataFirst, storeId);

            if(storeDataFirst.storeUid === ''){
              this.cancellableUpdate = makeCancellablePromise(
                updateDoc(
                  doc(db, 'store', storeId), {
                    storeUid: user.uid
                  }
                )
              );
          
              this.cancellableUpdate.promise.then((updateResponse) => {
                this.unsubscribeStore = onSnapshot(doc(db, 'store', storeId), (storeDoc) => {
                  const storeData = storeDoc.data();
            
                  if(storeData.storeStatus && storeData.storeActive){
                    this.setState({
                      signIn: true,
                      dataStore: {
                        id: storeDoc.id,
                        uid: storeData.storeUid,
                        phone: storeData.storePhone,
                        email: storeData.storeEmail,
                        name: storeData.storeName,
                        about: storeData.storeDesc,
                        province: {
                          value: storeData.storeProvince.storeProvinceId,
                          label: storeData.storeProvince.storeProvinceName
                        },
                        city: {
                          value: storeData.storeCity.storeCityId,
                          label: storeData.storeCity.storeCityName
                        },
                        district: {
                          value: storeData.storeDistrict.storeDistrictId,
                          label: storeData.storeDistrict.storeDistrictName
                        },
                        postal: storeData.storePostal,
                        address: storeData.storeAddress,
                        addressFull: storeData.storeAddress + ', Kec. ' + storeData.storeDistrict.storeDistrictName + ', ' + storeData.storeCity.storeCityName + ', ' + storeData.storeProvince.storeProvinceName + storeData.storePostal,
                        // approve: storeData.storeApprove
                      }
                    });
                  }else{
                    this.logOut();
                  }
                }, (error) => {
                  console.error(error);
            
                  toast.error(() => (<>{error.code}<span>{error.message}</span></>));
                
                  this.logOut();
                });
              }).catch((error) => {
                console.error(error);
          
                toast.error(() => (<>{error.code}<span>{error.message}</span></>));
          
                // this.setState({ formLoginLoading: false });
              });
            }else{
              this.unsubscribeStore = onSnapshot(doc(db, 'store', storeId), (storeDoc) => {
                const storeData = storeDoc.data();
          
                if(storeData.storeStatus && storeData.storeActive){
                  this.setState({
                    signIn: true,
                    dataStore: {
                      id: storeDoc.id,
                      uid: storeData.storeUid,
                      phone: storeData.storePhone,
                      email: storeData.storeEmail,
                      name: storeData.storeName,
                      about: storeData.storeDesc,
                      province: {
                        value: storeData.storeProvince.storeProvinceId,
                        label: storeData.storeProvince.storeProvinceName
                      },
                      city: {
                        value: storeData.storeCity.storeCityId,
                        label: storeData.storeCity.storeCityName
                      },
                      district: {
                        value: storeData.storeDistrict.storeDistrictId,
                        label: storeData.storeDistrict.storeDistrictName
                      },
                      postal: storeData.storePostal,
                      address: storeData.storeAddress,
                      addressFull: storeData.storeAddress + ', Kec. ' + storeData.storeDistrict.storeDistrictName + ', ' + storeData.storeCity.storeCityName + ', ' + storeData.storeProvince.storeProvinceName + storeData.storePostal,
                      // approve: storeData.storeApprove
                    }
                  });
                }else{
                  this.logOut();
                }
              }, (error) => {
                console.error(error);
          
                toast.error(() => (<>{error.code}<span>{error.message}</span></>));
              
                this.logOut();
              });
            }
          }else{
            if(this.unsubscribeStore){
              this.unsubscribeStore();
            }

            toast.error(() => (<>Opps...<span>Kamu belum terdaftar.</span></>));

            this.logOut();
          }
        }).catch((error) => {
          console.error(error);
    
          toast.error(() => (<>{error.code}<span>{error.message}</span></>));
          
          this.logOut();
        });
      }else{
        if(this.unsubscribeStore){
          this.unsubscribeStore();
        }

        this.setState({
          signIn: false,
          dataStore: '',
        });
      }
    });

    this.loadSetting();
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.signIn !== prevState.signIn){
      if(prevState.signIn !== ''){
        if(this.state.signIn){
          toast.success(() => (<>Masuk Berhasil<span>Hallo <strong>{this.state.dataStore.name}</strong>!</span></>));
        }else{
          toast.success(() => (<>Keluar Berhasil<span>Selamat tinggal!</span></>));
        }
      }
    }
  }

  componentWillUnmount(){
    if(this.cancellableStore){
      this.cancellableStore.cancel();
    }

    if(this.unsubscribeStore){
      this.unsubscribeStore();
    }
    
    if(this.cancellableLogout){
      this.cancellableLogout.cancel();
    }
    
    if(this.cancellableSetting){
      this.cancellableSetting.cancel();
    }
    
    if(this.cancellableUpdate){
      this.cancellableUpdate.cancel();
    }
  }

  updateSign(store, phone, nameFirst, nameLast){
    this.setState({
      dataSignIn: {
        store: store,
        phone: phone,
        nameFirst: nameFirst,
        nameLast: nameLast
      }
    });
  }

  logOut(){
    this.cancellableLogout = makeCancellablePromise(signOut(auth));

    this.cancellableLogout.promise.then(() => {
      // this.setState({
      //   signIn: false,
      //   dataStore: {
      //     uid: '',
      //     phone: ''
      //   }
      // });
    }).catch((error) => {
      console.error(error);
      toast.error(() => (<>{error.code}<span>{error.message}</span></>));
    });
  }

  loadSetting(){
    this.cancellableSetting = makeCancellablePromise(
      getDocs(
        query(
          collection(db, 'setting'), limit(1)
        )
      )
    );

    this.cancellableSetting.promise.then((settingSnapshot) => {
      if(settingSnapshot.size){
        const settingData = settingSnapshot.docs[0].data(),
              copyNow = (new Date()).getFullYear();

        this.setState({
          setting: {
            url: 'https://toko.indomakita.co.id',
            dateFormat: settingData.settingAdminDate,
            dateTime: settingData.settingAdminTime,
            dateTimeFormat: settingData.settingAdminDate + ' ' + settingData.settingAdminTime,
            safeMode: false,
            desc: settingData.settingService,
            copy: settingData.settingCopyright === copyNow ? settingData.settingCopyright : settingData.settingCopyright + ' - ' + copyNow,
            wa: settingData.settingWhatsappPass,
          },
          settingLoad: false
        });
      }
    }).catch((error) => {
      console.error(error);

      toast.error(() => (<>{error.code}<span>{error.message}</span></>));
    });
  }

  render(){
    if(this.state.signIn === ''){
      return(
        <Loading text="Memeriksa autentikasi..." page />
      );
    }else{
      if(this.state.settingLoad){
        return(
          <Loading text="Memuat pengaturan..." page />
        );
      }else{
        return(
          <Suspense fallback={
            <Loading text="Memuat halaman..." page />
          }>
            <Routes>
              <Route path="/login" element={
                this.state.signIn ? (<Navigate to="/admin" />) : (<Login updateSign={this.updateSign} setting={this.state.setting} outLink={this.outLink} />)
              } />
              {/* <Route path="/daftar" element={
                this.state.signIn ? (<Navigate to="/admin" />) : (<Register updateSign={this.updateSign} setting={this.state.setting} outLink={this.outLink} />)
              } /> */}
              <Route path="/admin/*" element={
                this.state.signIn ? (<Dashboard dataStore={this.state.dataStore} setting={this.state.setting} logOut={this.logOut} outLink={this.outLink} />) : (<Navigate to="/login" />)
              } />
              <Route path="*" element={
                this.state.signIn ? (<Navigate to="/admin" />) : (<Navigate to="/login" />)
              } />
            </Routes>
          </Suspense>
        );
      }
    }
  }
}

export default App;